(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/join-team/assets/javascripts/join-team.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/join-team/assets/javascripts/join-team.js');
'use strict';

const {
  urlMapping
} = svs.core;
const {
  useEffect,
  useRef
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  Redirect
} = ReactRouterDOM;
const {
  actions
} = svs.components.marketplaceData.joinTeam;
const {
  ReactButton: Button
} = svs.ui;
const {
  GroupHeader
} = svs.components.marketplace.groupHeader;
const {
  getColor
} = svs.components.marketplace.colorHelper;
const {
  showDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const REDIRECTED_FROM_LOGIN = 'redirectedFromLogin';

const JoinTeam = _ref => {
  var _svs;
  let {
    match
  } = _ref;
  const dispatch = useDispatch();
  const hasFetchedInvitation = useRef(false);
  const termsUrl = "/dokument/".concat((_svs = svs) === null || _svs === void 0 || (_svs = _svs.marketplace) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 || (_svs = _svs.marketplaceDocumentId) === null || _svs === void 0 ? void 0 : _svs.documentId);
  const {
    loading,
    redirect,
    teamId,
    teamName
  } = useSelector(state => state.JoinTeam);
  const isCurrentUserAuthenticated = Boolean(useSelector(state => state.customerId));
  const searchParams = new URLSearchParams(location.search);
  const getReturnUrl = () => "".concat(window.location.pathname, "?").concat(REDIRECTED_FROM_LOGIN, "=true");
  useEffect(() => {
    if (hasFetchedInvitation.current === false) {
      hasFetchedInvitation.current = true;
      dispatch(actions.fetchInvitation(match.params.invitationToken));
    }
  }, [dispatch, match]);
  if (redirect) {
    return React.createElement(Redirect, {
      to: redirect
    });
  }
  if (loading) {
    return React.createElement("div", null, "Loading...");
  }
  const acceptInvitation = () => {
    if (!isCurrentUserAuthenticated) {
      window.location = "".concat(urlMapping.get('loginHome'), "?returnUrl=").concat(getReturnUrl());
      return;
    }
    dispatch(actions.acceptInvite(match.params.invitationToken));
  };
  if (isCurrentUserAuthenticated && searchParams.get(REDIRECTED_FROM_LOGIN)) {
    acceptInvitation();
  }
  const declineInvitation = () => {
    dispatch(showDialog({
      title: 'Är du säker på att du vill tacka nej?',
      text: 'Inbjudningslänken fortsätter att vara giltig om du ångrar dig.',
      icon: 'exclamation-sign',
      buttons: [{
        text: 'Avbryt'
      }, {
        text: 'Ja',
        callback: () => {
          dispatch(actions.cancelJoin());
        }
      }]
    }));
  };
  return React.createElement("div", {
    className: "join-team-wrapper"
  }, React.createElement("div", {
    className: "join-team-page"
  }, React.createElement("h2", {
    className: "f-center f-800 margin-top-1"
  }, "Vi vill ha med dig i laget!"), teamName && React.createElement(GroupHeader, {
    className: "team-list-heading",
    color: getColor(teamId),
    teamName: teamName
  }), React.createElement("div", {
    className: "join-team-rules-terms"
  }, React.createElement("p", {
    className: "padding-top-2"
  }, "Genom att g\xE5 med i ett lag godk\xE4nner du", ' ', React.createElement("a", {
    className: "qa-join-terms",
    href: termsUrl,
    rel: "noopener noreferrer",
    target: "_blank"
  }, "villkoren f\xF6r Spela tillsammans"), "."))), React.createElement("div", {
    className: "join-team-buttons"
  }, React.createElement(Button, {
    block: true,
    className: "qa-join-accept",
    inverted: true,
    onClick: acceptInvitation
  }, "G\xE5 med i laget"), React.createElement(Button, {
    block: true,
    className: "qa-join-decline",
    onClick: declineInvitation,
    transparent: true
  }, "Nej tack")));
};
setGlobal('svs.marketplace.components.joinTeam.JoinTeam', JoinTeam);

 })(window);